import { ILoyaltyProduct } from "./loyaltyProduct.model"

export interface ILoyaltyCollection {
    id?: number
    tag_id?: string
    name?: string
    description?: string
    is_active?: boolean
    effective_date?: [Date, Date]
    effective_date_start?: Date | null
    effective_date_end?: Date | null
    collection_type?: string
    giftCollectionItem?: ILoyaltyCollectionItem[]
    created_by?: string
    updated_by?: string
    updated_at?: Date | null
    created_at?: Date | null
}

export interface ILoyaltyCollectionItem {
    id?: number
    gift_id?: number
    gift_collection_id?: number
    price?: number
    stock?: number
    sorting?: number
    point?: number
    is_active?: boolean
    gifts?: ILoyaltyProduct
    created_by?: string
    updated_by?: string
    updated_at?: Date | null
    created_at?: Date | null
}

export interface ILoyaltyCollectionItemForm {
    id?: number
    collection_id?: number
    gift_ids?: number[]
    gifts?: ILoyaltyProduct
    product_name?: string
    point?: number
    price?: number
    stock?: number
    sorting?: number
    is_active?: boolean
}

export class LoyaltyCollectionFormRerquest {
    stock?: number
    price?: number
    sorting?: number
    point?: number
}
