export enum PageRoute {
    Dashboard = "/dashboard",
    Login = "/login",
    Profile = "/profile",
    Categories = "/categories",
    Coupons = "/coupons",
    Settings = "/settings",
    MasterCenter = "/master-center",
    ContentManagements = "/content-managements",
    FAQs = "/general-contents/FAQs",
    NotFound = "/404",
    UserManagement = "/users-management",
    PersonalExperience = "/general-contents/personal-experience",
    MediaManagement = "/media-management",
    MasterPage = "/master-page",
    SettingClearCache = "/setting/clear-cache",
    SettingGeneral = "/setting/general",
    GroupRoleManagement = "/group-role-management",
    LoyaltyProduct = "/loyalty-gifts",
    LoyaltyCategory = "/loyalty-categories",
    LoyaltyMember = "/loyalty-members",
    LoyaltyTags = "/loyalty-tags",
    LoyaltyCollections = "/loyalty-collections",
    PromotionManagement = "/promotion-management",
    LoyaltyCampaigns = "/loyalty-campaigns",
    FeedbackManagement = "/feedback-management",
}

export enum ParamsEnum {
    ID = "id",
    CATE_TYPE_ID = "cate_type_id",
    CATE_TYPE_NAME = "cate_type_name",
    CATEGORY_ID = "category_id",
    SUB_CATEGORY_ID = "sub_category_id",
    CAT_SLUG = "cat_slug",
    SUB_CAT_SLUG = "sub_cat_slug",
    COLLECTION_ITEM_ID = "collection_item_id",
}
