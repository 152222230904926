export enum RoleUserEnum {
    Admin = "admin",
    Partner = "partner",
    Staff = "staff",
}

export enum GenderEnum {
    Male = "male",
    Female = "female",
    Others = "others",
}

export enum PermissionUserEnum {
    MediaManagement = "media_management",
    UserManagement = "user_management",
    ContentManagement = "content_management",
    CategoryManagement = "category_management",
    SettingsManagement = "settings_management",
    SettingsGeneralManagement = "settings_general_management",
    CouponManagement = "coupon_management",
    MasterPage = "master_page",
    MasterCenter = "master_center",
    UserInfo = "user_info",
    GroupRoleManagement = "group_role_management",
    ApprovalManagement = "approval_management",
    PageManagement = "page_management",
    ListMediaManagement = "list_media_management",
    ViewMedia = "view_media",

    LoyaltyViewMembers = "view_members",
    LoyaltyCreateMembers = "create_members",
    LoyaltyEditMembers = "edit_members",
    LoyaltyDeleteMembers = "delete_members",

    LoyaltyViewTags = "view_tags",
    LoyaltyCreateTags = "create_tags",
    LoyaltyEditTags = "edit_tags",
    LoyaltyDeleteTags = "delete_tags",

    LoyaltyTagManagement = "tag_management",

    LoyaltyViewGift = "view_gift",
    LoyaltyCreateGift = "create_gift",
    LoyaltyEditGift = "edit_gift",
    LoyaltyDeleteGift = "delete_gift",

    LoyaltyViewProductCategory = "view_gift_category",
    LoyaltyCreateProductCategory = "create_gift_category",
    LoyaltyEditProductCategory = "edit_gift_category",
    LoyaltyDeleteProductCategory = "delete_gift_category",

    LoyaltyViewGiftCollection = "view_gift_collection",

    PromotionManagement = "promotion_management",

    LoyaltyCompaign = "campaign_view",

    FeedbackManagement = "feedback_management",
}
