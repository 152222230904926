export const env = {
    ENV: "production",
    API_BO_ENDPOINT: "https://api-bo.vicensavings.com",
    FO_URL: "https://coupons.vicensavings.com",
    FO_X_API_KEY: "dQXPWgXFggbkJPImKLTfekoooYoTIIlJqrcswWRCKEFrI",
    SUB_PATH_BO: "ali-service-api",
    SUB_PATH_LOYALTY_BO: "loyalty-service-api",
    SUB_PATH_FO: "fo",
    SUB_PATH_LOYALTY_FO: "loyalty-fo-api",
}
