import { DataResponse, ILoyaltyProduct, IRequestPaging, LoyaltyGiftFormRerquest } from "@/models"
import { baseQueryLoyaltyBO } from "./baseQuery.service"
import { createApi } from "@reduxjs/toolkit/query/react"
import { DataMapper } from "@/helpers/utilities"

export const loyaltyProductService = createApi({
    reducerPath: "loyaltyProductService",
    baseQuery: baseQueryLoyaltyBO,
    endpoints: (builder) => ({
        getLoyaltyProductsApi: builder.query<DataResponse<ILoyaltyProduct[]>, IRequestPaging>({
            query: (params: IRequestPaging) => ({
                url: "/loyal-gifts",
                params,
                method: "GET",
            }),
        }),
        createLoyaltyProductApi: builder.mutation<DataResponse<ILoyaltyProduct>, ILoyaltyProduct>({
            query: (body: ILoyaltyProduct) => ({
                url: `/loyal-gifts`,
                body,
                method: "POST",
            }),
        }),
        updateLoyaltyProductApi: builder.mutation<DataResponse<ILoyaltyProduct>, ILoyaltyProduct>({
            query: (body: ILoyaltyProduct) => ({
                url: `/loyal-gifts/${body?.id}`,
                body: DataMapper(body, new LoyaltyGiftFormRerquest()),
                method: "PUT",
            }),
        }),

        deleteLoyaltyProductApi: builder.mutation<string, ILoyaltyProduct>({
            query: (body: ILoyaltyProduct) => ({
                url: `/loyal-gifts/${body?.id}`,
                method: "DELETE",
            }),
        }),
    }),
})

export const {
    useGetLoyaltyProductsApiQuery,
    useLazyGetLoyaltyProductsApiQuery,

    useCreateLoyaltyProductApiMutation,

    useUpdateLoyaltyProductApiMutation,

    useDeleteLoyaltyProductApiMutation,
} = loyaltyProductService
