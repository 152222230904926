import {
    DataResponse,
    ILoyaltyMember,
    ILoyaltyTag,
    IRequestPaging,
    LoyaltyTagFormCreateRequest,
    LoyaltyTagFormUpdateRequest,
    LoyaltyTagMemberListRequest,
    RequestPaging,
} from "@/models"
import { baseQueryLoyaltyBO } from "./baseQuery.service"
import { createApi } from "@reduxjs/toolkit/query/react"
import { DataMapper } from "@/helpers/utilities"

export const loyaltyTagService = createApi({
    reducerPath: "loyaltyTagService",
    baseQuery: baseQueryLoyaltyBO,
    endpoints: (builder) => ({
        getLoyaltyTagsApi: builder.query<DataResponse<ILoyaltyTag[]>, IRequestPaging>({
            query: (params: IRequestPaging) => ({
                url: "/loyal-tag",
                params,
                method: "GET",
            }),
        }),
        getMembersByLoyaltyTagsApi: builder.query<DataResponse<ILoyaltyMember[]>, LoyaltyTagMemberListRequest>({
            query: (params: LoyaltyTagMemberListRequest) => ({
                url: `/loyal-tag/${params?.tag_id}/members`,
                params: DataMapper(params, new RequestPaging()),
                method: "GET",
            }),
        }),
        createLoyaltyTagApi: builder.mutation<DataResponse<ILoyaltyTag>, ILoyaltyTag>({
            query: (body: ILoyaltyTag) => ({
                url: `/loyal-tag`,
                body: DataMapper(body, new LoyaltyTagFormCreateRequest()),
                method: "POST",
            }),
        }),
        updateLoyaltyTagApi: builder.mutation<string, ILoyaltyTag>({
            query: (body) => ({
                url: `/loyal-tag/${body?.id}`,
                body: DataMapper(body, new LoyaltyTagFormUpdateRequest()),
                method: "PUT",
            }),
        }),
        deleteLoyaltyTagApi: builder.mutation<string, ILoyaltyTag>({
            query: (body: ILoyaltyTag) => ({
                url: `/loyal-tag/${body?.id}`,
                method: "DELETE",
            }),
        }),
        filterAnalysisLoyaltyTagApi: builder.mutation<string, ILoyaltyTag>({
            query: (body) => ({
                url: `/loyal-tag/filter-analyses/${body?.id}`,
                method: "PUT",
            }),
        }),
    }),
})

export const {
    useGetLoyaltyTagsApiQuery,
    useLazyGetLoyaltyTagsApiQuery,

    useGetMembersByLoyaltyTagsApiQuery,
    useLazyGetMembersByLoyaltyTagsApiQuery,

    useFilterAnalysisLoyaltyTagApiMutation,

    useCreateLoyaltyTagApiMutation,

    useUpdateLoyaltyTagApiMutation,

    useDeleteLoyaltyTagApiMutation,
} = loyaltyTagService
