import { DataResponse, ILoyaltyRule } from "@/models"
import { baseQueryLoyaltyBO } from "./baseQuery.service"
import { createApi } from "@reduxjs/toolkit/query/react"

export const loyaltyRuleService = createApi({
    reducerPath: "loyaltyRuleService",
    baseQuery: baseQueryLoyaltyBO,
    endpoints: (builder) => ({
        getLoyaltyRules: builder.query<DataResponse<ILoyaltyRule[]>, void>({
            query: () => ({
                url: "/loyalty-rules",
                method: "GET",
            }),
            transformResponse: (response: ILoyaltyRule[]) => ({
                data: response,
            }),
        }),
    }),
})

export const { useGetLoyaltyRulesQuery, useLazyGetLoyaltyRulesQuery } = loyaltyRuleService
