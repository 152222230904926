export interface ILoyaltyCampaign {
    id?: string
    name?: string
    description?: string
    active_from?: string
    active_to?: string
    date?: Date[] | null[]
    target_id?: number
    target_reference_id?: number
    reward_type_id?: number
    reward_reference_id?: number
    campaignRewardTypes?: ILoyaltyRewardType[]
    campaignTargets?: ILoyaltyTargetType[]
}

export interface ILoyaltyTargetType {
    id?: number
    name?: string
    description?: string
    is_active?: boolean
    sorting?: number
    created_at?: Date | null
    updated_at?: Date | null
}

export interface ILoyaltyRewardType {
    id?: number
    name?: string
    description?: string
    is_active?: boolean
    sorting?: number
    created_at?: Date | null
    updated_at?: Date | null
}

export class LoyaltyCampaignFormRerquest {
    name?: string
    description?: string
    active_from?: Date | null
    active_to?: Date | null
    target_id?: number
    target_reference_id?: number
    reward_type_id?: number
    reward_reference_id?: number
}
