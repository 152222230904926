import { RequestPaging } from "./common.model"

export interface ILoyaltyTag {
    id?: number
    name?: string
    description?: string
    is_active?: boolean
    is_rule?: boolean
    rule_details_ids?: number[]
    created_by?: string
    updated_by?: string
    created_at?: Date | null
    updated_at?: Date | null
}

export class LoyaltyTagFormCreateRequest {
    name?: string
    description?: string
}

export class LoyaltyTagFormUpdateRequest {
    name?: string
    description?: string
    is_rule?: boolean
    rule_details_ids?: number[]
}

export class LoyaltyTagMemberListRequest extends RequestPaging {
    tag_id?: number
}
