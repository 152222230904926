import { CampaignStatusEnum, ContentStatusEnum, SortOrderEnum } from "@/enums"
import { SortOrder, TablePaginationConfig } from "antd/es/table/interface"

export class Common {
    public static renderData(data: any) {
        return data ? data : "N/A"
    }
    public static renderAliasTag(email: string) {
        return email ? email.toUpperCase().substring(0, 2) : "N/A"
    }
    public static getColorTagContentByStatus(status: ContentStatusEnum) {
        let color = ""
        let className = `tag-${status}`

        switch (status) {
            case ContentStatusEnum.APPROVED:
                color = "green-inverse"
                break
            case ContentStatusEnum.WAITING:
                color = "ogrange-inverse"
                break
            case ContentStatusEnum.REJECTED:
                color = "red-inverse"
                break
        }
        return { color, className, name: status }
    }
    public static getColorTagStatusCampaign(status: CampaignStatusEnum) {
        let color = ""
        let className = `tag-${status}`

        switch (status) {
            case CampaignStatusEnum.DONE:
                color = "green"
                break
            case CampaignStatusEnum.ON_GOING:
                color = "green-inverse"
                break
            case CampaignStatusEnum.PENDING:
                color = "ogrange-inverse"
                break
            case CampaignStatusEnum.REMOVED:
                color = "red-inverse"
                break
        }
        return { color, className, name: status }
    }
    public static convertTypeSorter(type: SortOrder) {
        switch (type) {
            case "ascend":
                return SortOrderEnum.ASC
            case "descend":
                return SortOrderEnum.DESC
            default:
                return null
        }
    }
    public static getColorTagByAlias(alias: string) {
        let hash = 0
        for (let i = 0; i < alias.length; i++) {
            hash = alias.charCodeAt(i) + ((hash << 5) - hash)
        }

        let color = "#"
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xff
            color += ("00" + value.toString(16)).substr(-2)
        }

        return color
    }
}

export interface IRequestPaging {
    page?: number
    limit?: number
    search?: string
    project_id?: string
    column?: string
    direction?: SortOrderEnum
}

export interface TablePaginationOverride extends TablePaginationConfig {
    column?: string
    direction?: SortOrderEnum
}

export type DataResponse<T> = {
    data?: T
    page?: number
    total?: number
    success?: boolean
    friendly_message?: string
    message?: string
    status?: number
}

export interface SelectOption {
    label: string
    value: string
}

export interface ILanguage {
    id?: string
    name?: string
    locale?: string
    project_id?: string
    iso_code?: string
    image?: string
    is_active?: boolean
    created_at?: Date | null
    updated_at?: Date | null
}

export interface ICommonStore {
    languages: ILanguage[]
}

export class RequestPaging {
    search?: string
    page?: number
    limit?: number
}
