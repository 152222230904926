import { DataResponse, ILoyaltyMember, ILoyaltyMemberGifts, IRequestPaging } from "@/models"
import { baseQueryLoyaltyBO } from "./baseQuery.service"
import { createApi } from "@reduxjs/toolkit/query/react"

export const loyaltyMemberService = createApi({
    reducerPath: "loyaltyMemberService",
    baseQuery: baseQueryLoyaltyBO,
    endpoints: (builder) => ({
        getLoyaltyMemberApi: builder.query<DataResponse<ILoyaltyMember[]>, IRequestPaging>({
            query: (params: IRequestPaging) => ({
                url: "/loyal-members",
                params,
                method: "GET",
            }),
        }),
        getLoyaltyMemberGiftsApi: builder.query<DataResponse<ILoyaltyMemberGifts[]>, { id: string }>({
            query: (params) => ({
                url: `/loyal-members/${params?.id}/gifts`,
                method: "GET",
            }),
            transformResponse: (response: ILoyaltyMemberGifts[]) => {
                return { data: response }
            },
        }),
        createLoyaltyMemberApi: builder.mutation<DataResponse<ILoyaltyMember>, ILoyaltyMember>({
            query: (body: ILoyaltyMember) => ({
                url: `/loyal-members`,
                body,
                method: "POST",
            }),
        }),
        updateLoyaltyMemberApi: builder.mutation<string, ILoyaltyMember>({
            query: ({ id, phone, channel, emails, ...body }) => ({
                url: `/loyal-members/${id}`,
                body,
                method: "PUT",
            }),
        }),
        deactiveLoyaltyMemberApi: builder.mutation<string, string>({
            query: (member_id: string) => ({
                url: `/loyal-members/deactive/${member_id}`,
                method: "PUT",
            }),
        }),
    }),
})

export const {
    useGetLoyaltyMemberApiQuery,
    useLazyGetLoyaltyMemberApiQuery,

    useGetLoyaltyMemberGiftsApiQuery,
    useLazyGetLoyaltyMemberGiftsApiQuery,

    useCreateLoyaltyMemberApiMutation,

    useUpdateLoyaltyMemberApiMutation,
    useDeactiveLoyaltyMemberApiMutation,
} = loyaltyMemberService
