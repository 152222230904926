import { DataResponse, IPromotion, ILoyaltyProduct, IRequestPaging } from "@/models"
import { baseQuery } from "./baseQuery.service"
import { createApi } from "@reduxjs/toolkit/query/react"

export const promotionService = createApi({
    reducerPath: "promotionService",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getPromotionsApi: builder.query<DataResponse<IPromotion[]>, IRequestPaging>({
            query: (params: IRequestPaging) => ({
                url: "/promo-products",
                params,
                method: "GET",
            }),
        }),
        createPromotionApi: builder.mutation<DataResponse<IPromotion>, ILoyaltyProduct>({
            query: (body: IPromotion) => ({
                url: `/promo-products`,
                body,
                method: "POST",
            }),
        }),
        updatePromotionApi: builder.mutation<string, IPromotion>({
            query: (body) => ({
                url: `/promo-products/${body?.id}`,
                body,
                method: "PUT",
            }),
        }),
        deletePromotionApi: builder.mutation<string, IPromotion>({
            query: (body: IPromotion) => ({
                url: `/promo-products/${body?.id}`,
                method: "DELETE",
            }),
        }),
    }),
})

export const {
    useGetPromotionsApiQuery,
    useLazyGetPromotionsApiQuery,

    useCreatePromotionApiMutation,

    useUpdatePromotionApiMutation,

    useDeletePromotionApiMutation,
} = promotionService
