import {
    DataResponse,
    ILoyaltyCampaign,
    ILoyaltyRewardType,
    ILoyaltyTargetType,
    IRequestPaging,
    LoyaltyCampaignFormRerquest,
} from "@/models"
import { baseQueryLoyaltyBO } from "./baseQuery.service"
import { createApi } from "@reduxjs/toolkit/query/react"
import { DataMapper } from "@/helpers/utilities"

export const loyaltyCampaignsService = createApi({
    reducerPath: "loyaltyCampaignsService",
    baseQuery: baseQueryLoyaltyBO,
    endpoints: (builder) => ({
        getLoyaltyCampaignsApi: builder.query<DataResponse<ILoyaltyCampaign[]>, IRequestPaging>({
            query: (params: IRequestPaging) => ({
                url: "/loyal-campaigns",
                params,
                method: "GET",
            }),
        }),
        getLoyaltyCampaignsTargetTypeApi: builder.query<DataResponse<ILoyaltyTargetType[]>, void>({
            query: () => ({
                url: "/loyal-campaigns/target-types",
                method: "GET",
            }),
            transformResponse: (response: ILoyaltyTargetType[]) => {
                return {
                    data: response,
                }
            },
        }),
        getLoyaltyCampaignsTargetTypeReferencesApi: builder.query<DataResponse<ILoyaltyTargetType[]>, { id?: number }>({
            query: (params) => ({
                url: `/loyal-campaigns/target-type/${params?.id}/references`,
                params,
                method: "GET",
            }),
            transformResponse: (response: ILoyaltyTargetType[]) => {
                return {
                    data: response,
                }
            },
        }),
        getLoyaltyCampaignsRewardTypeApi: builder.query<DataResponse<ILoyaltyRewardType[]>, void>({
            query: () => ({
                url: `/loyal-campaigns/reward-types`,
                method: "GET",
            }),
            transformResponse: (response: ILoyaltyRewardType[]) => {
                return {
                    data: response,
                }
            },
        }),
        getLoyaltyCampaignsRewardTypeReferencesApi: builder.query<DataResponse<ILoyaltyRewardType[]>, { id?: number }>({
            query: (params) => ({
                url: `/loyal-campaigns/reward-type/${params?.id}/references`,
                method: "GET",
            }),
            transformResponse: (response: ILoyaltyRewardType[]) => {
                return {
                    data: response,
                }
            },
        }),
        createLoyaltyCampaignApi: builder.mutation<DataResponse<ILoyaltyCampaign>, ILoyaltyCampaign>({
            query: (body: ILoyaltyCampaign) => ({
                url: `/loyal-campaigns`,
                body: DataMapper(body, new LoyaltyCampaignFormRerquest()),
                method: "POST",
            }),
        }),
        updateLoyaltyCampaignApi: builder.mutation<string, ILoyaltyCampaign>({
            query: (body) => ({
                url: `/loyal-campaigns/${body?.id}`,
                body: DataMapper(body, new LoyaltyCampaignFormRerquest()),
                method: "PUT",
            }),
        }),

        deleteLoyaltyCampaignApi: builder.mutation<string, ILoyaltyCampaign>({
            query: (body: ILoyaltyCampaign) => ({
                url: `/loyal-campaigns/${body?.id}`,
                method: "DELETE",
            }),
        }),
    }),
})

export const {
    useGetLoyaltyCampaignsApiQuery,
    useLazyGetLoyaltyCampaignsApiQuery,

    useGetLoyaltyCampaignsTargetTypeApiQuery,
    useLazyGetLoyaltyCampaignsTargetTypeApiQuery,

    useGetLoyaltyCampaignsTargetTypeReferencesApiQuery,
    useLazyGetLoyaltyCampaignsTargetTypeReferencesApiQuery,

    useGetLoyaltyCampaignsRewardTypeApiQuery,
    useLazyGetLoyaltyCampaignsRewardTypeApiQuery,

    useGetLoyaltyCampaignsRewardTypeReferencesApiQuery,
    useLazyGetLoyaltyCampaignsRewardTypeReferencesApiQuery,

    useCreateLoyaltyCampaignApiMutation,

    useUpdateLoyaltyCampaignApiMutation,

    useDeleteLoyaltyCampaignApiMutation,
} = loyaltyCampaignsService
